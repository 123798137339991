<template>
	<a-table :columns="columns" :data-source="bills">
    <a slot="code" slot-scope="code,item" @click="redirectToDetails(item.id)">{{ code }}</a>
  </a-table>
</template>
<script>
  import billsByResource from "@/services/order/billsByResource"
  
  export default {
    props: {
      server: {
        type: Object,
        default: ()=> ({})
      }
    },
    data () {
      return {
        bills : [],
        columns : [
    		{
    			title : "Code",
    			dataIndex :'code',
    			key : 'code',
          scopedSlots: { customRender: 'code' },
    		},
    		{
    			title : "Số tiền",
    			dataIndex :'price',
    			key : 'money',
          customRender: (val) => {
            return `${val.toLocaleString()}`;
          },
    		},
    		{
    			title : "Số tiền thanh toán",
    			dataIndex :'payment_price',
    			key : 'payment',
          customRender: (val) => {
            return `${val.toLocaleString()}`;
          },
    		},
        {
          title : "Trạng thái",
          dataIndex :'status',
          key : 'status',
          customRender: (val) => {
            let txt;
            switch(val){
              case 0:
                txt = 'Chờ thanh toán';
                break;
              case 1:
                txt = 'Đã thanh toán';
                break;
              case 2:
                txt = 'Đã hủy';
                break;
              default:
                txt = 'Lỗi !';
            }
            return txt;
          },
        },
        {
          title : "Ngày thanh toán",
          dataIndex :'payment_date',
          key : 'payment_date',
        },
    		{
    			title : "Ngày tạo",
    			dataIndex :'created_at',
    			key : 'created'
    		},
    		{
    			title : "Ngày hết hạn",
    			dataIndex :'end_time',
    			key : 'endTime'
    		},
    	],
      }
    },
    async created() {
      this.loading = true
      this.bills = (await billsByResource(this.server.id)).data
      this.loading = false
    },
    methods: {
      redirectToDetails(id) {
        this.$router.push({ name: 'billing.detail', params: { id } });
      },
    },
  }
</script>